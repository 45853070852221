import React, { useEffect, useState } from "react";

const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const updateCountdown = () => {
      const targetDate = new Date("2024-12-31T00:00:00"); // Set your target launch date here
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({
        days: String(days).padStart(2, "0"),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      });
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    margin: 0,
    padding: 0,
    backgroundColor: "#000", // Black background
    color: "#fff", // White text
    textAlign: "center",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const contentStyle = {
    maxWidth: "900px",
    margin: "0 auto",
    padding: "50px",
    background: "#222", // Dark grey background for content
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0,0,0,0.5)",
  };

  const countdownStyle = {
    fontSize: "1.25rem",
    margin: "20px 0",
  };

  const timerStyle = {
    fontSize: "2rem",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
    marginTop: "10px",
  };

  const timerUnitStyle = {
    background: "#444", // Slightly lighter grey background for timer units
    padding: "10px",
    borderRadius: "5px",
    color: "#f00", // Red color for timer units
  };

  const formStyle = {
    marginTop: "20px",
  };

  const inputStyle = {
    padding: "10px",
    border: "1px solid #555", // Darker grey border
    borderRadius: "5px",
    fontSize: "1rem",
    width: "80%",
    maxWidth: "400px",
    backgroundColor: "#333", // Dark grey background for input
    color: "#fff", // White text for input
  };

  const buttonStyle = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#f00", // Red background for button
    color: "white",
    fontSize: "1rem",
    cursor: "pointer",
    marginTop: "10px",
  };

  const buttonHoverStyle = {
    backgroundColor: "#d00", // Darker red on hover
  };

  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <header>
          <h1 style={{ color: "#f00" }}>Kaan Media</h1>{" "}
          {/* Red text for header */}
          <p
            style={{ fontSize: "1.5rem", color: "#f00", margin: "10px 0 20px" }}
          >
            We're launching soon!
          </p>{" "}
          {/* Red text for sub-header */}
        </header>
        <main>
          <div style={countdownStyle}>
            <p>Launching in:</p>
            <div style={timerStyle}>
              <span style={timerUnitStyle}>{timeLeft.days}</span> days
              <span style={timerUnitStyle}>{timeLeft.hours}</span> hours
              <span style={timerUnitStyle}>{timeLeft.minutes}</span> minutes
              <span style={timerUnitStyle}>{timeLeft.seconds}</span> seconds
            </div>
          </div>
          <p>Subscribe to our newsletter for updates:</p>
          <form style={formStyle} action="#" method="post">
            <input
              type="email"
              placeholder="Your email address"
              required
              style={inputStyle}
            />
            <button
              type="submit"
              style={buttonStyle}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor =
                  buttonHoverStyle.backgroundColor)
              }
              onMouseOut={(e) => (e.target.style.backgroundColor = "#f00")}
            >
              Subscribe
            </button>
          </form>
        </main>
        <footer
          style={{ marginTop: "30px", fontSize: "0.875rem", color: "#777" }}
        >
          <p>© 2024 Kaan Media. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default ComingSoon;
