import create from "zustand";
import { baseUrl } from "../api/api.js";

// Store for handling instrument LTP data
const ohlcDayBeforeCandles = create((set) => ({
  instrumentLtpDatas: {}, // State to store the API data, initialized as an empty object
  error: null, // State to store error messages

  // Action to make a GET API call and store the response
  ohlcDayBeforeCandle: async () => {
    try {
      const response = await fetch(
        `${baseUrl}/sensexroutes/get/GetInstrumentListLastHourController`,
        {
          method: "GET",
          credentials: "include", // Important to include credentials (cookies)
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Map and store data by instruments like BSE:SENSEX, NSE:INFY, etc.
      set({ instrumentLtpDatas: data, error: null });
    } catch (error) {
      console.log("🚀 ~ ohlcDayBeforeCandle: ~ error:", error);
      set({ error: error.message, instrumentLtpDatas: {} });
    }
  },
}));

export default ohlcDayBeforeCandles;
