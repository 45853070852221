import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useInstrumentLtpStore from "../../stores/instrumentLtpStore.js";
import useCandleStore from "../../stores/useCandleStore.js";
import "./tradesTable.css";

const TradesTable = () => {
  const { fetchInstrumentLtp, instrumentLtpData, error } =
    useInstrumentLtpStore();
  const { candles, fetchCandles, isLoading } = useCandleStore();
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(60);
  console.log("🚀 ~ TradesTable ~ instrumentLtpData:", instrumentLtpData);

  const fetchData = async () => {
    setLoading(true);
    await fetchInstrumentLtp();
    await fetchCandles();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          fetchData();
          return 60;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  }, [error]);

  return (
    <div style={styles.container}>
      <ToastContainer />
      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              {/* <th style={styles.headerCell}>ID</th> */}
              <th style={styles.headerCell}>Indices</th>
              <th style={styles.headerCell}>CMP</th>
              {/* <th style={styles.headerCell}>Yesterday</th> */}
              <th style={styles.headerCell}>% high candle 11:15am</th>
              <th style={styles.headerCell}>% low candle 11:15am</th>
              {/* <th style={styles.headerCell}>Yesterday High</th>
              <th style={styles.headerCell}>Yesterday Low</th>
              <th style={styles.headerCell}>High</th>
              <th style={styles.headerCell}>Low</th>
              <th style={styles.headerCell}>High/Low Avg</th>
              <th style={styles.headerCell}>high/Low YEST</th> */}
            </tr>
          </thead>
          <tbody>
            {instrumentLtpData &&
              Object.entries(instrumentLtpData).map(
                ([indexName, details], idx) => {
                  const { lastPrice, ohlc, ohlcCandle, indexSymbol } = details;

                  // Assuming you're interested in the latest candle, we'll pick the last one
                  const latestCandle = ohlcCandle[0];

                  // Set color based on the latest candle's values
                  let lastPriceColor = "#fff";

                  if (lastPrice >= latestCandle?.high) {
                    lastPriceColor = "green";
                  } else if (lastPrice <= latestCandle.low) {
                    lastPriceColor = "red";
                  } else {
                    lastPriceColor = "yellow";
                  }

                  // Set color based on the latest candle's values
                  let lastPriceColor2 = "#fff";

                  if (
                    lastPrice >=
                    (latestCandle?.high + latestCandle?.low) / 2
                  ) {
                    lastPriceColor2 = "green";
                  } else if (
                    lastPrice <=
                    (latestCandle?.high + latestCandle?.low) / 2
                  ) {
                    lastPriceColor2 = "red";
                  } else {
                    lastPriceColor2 = "yellow";
                  }
                  // } else if (
                  //   lastPrice <= ohlc.high &&
                  //   lastPrice >= ohlc.low
                  // ) {

                  // lastPriceColor = "yellow";

                  return (
                    <tr key={indexSymbol} style={styles.row}>
                      {/* <td style={styles.cell}>{idx + 1}</td>/ */}
                      <td style={styles.cell}>{indexSymbol}</td>
                      <td
                        style={{
                          ...styles.cell,
                          backgroundColor: lastPriceColor,
                        }}
                      >
                        {lastPrice?.toFixed(0)}
                        {/* <br />
                        {ohlc.high}
                        <br />
                        {ohlc.low} */}
                      </td>
                      {/* <td
                        style={{
                          ...styles.cell,
                          backgroundColor: lastPriceColor2,
                        }}
                      ></td> */}
                      <td style={styles.cell}>
                        {(100 - (ohlcCandle[0]?.low / lastPrice) * 100).toFixed(
                          2
                        ) >= 0 ? (
                          <>
                            <a>{ohlcCandle[0]?.high.toFixed(2)}</a>
                            <br />
                            {(
                              100 -
                              (ohlcCandle[0]?.high / lastPrice) * 100
                            ).toFixed(2)}
                            %
                            <br />
                          </>
                        ) : (
                          <>
                            <a>{ohlcCandle[0]?.high.toFixed(2)}</a>
                            <br />
                            {(
                              100 -
                              (ohlcCandle[0]?.high / lastPrice) * 100
                            ).toFixed(2)}
                            %
                            <br />
                          </>
                        )}
                        {/* <a> {ohlcCandle[0].low.toFixed(2)}</a>
                        <br />
                        {(100 - (ohlcCandle[0].low / lastPrice) * 100).toFixed(
                          2
                        )}
                        % &nbsp;
                        <br /> */}
                        {/* Low:{" "}
                        {((lastPrice / ohlc.low) * 100).toFixed(2)}% */}
                      </td>

                      <td style={styles.cell}>
                        {(100 - (ohlcCandle[0]?.low / lastPrice) * 100).toFixed(
                          2
                        ) >= 0 ? (
                          <>
                            <a>{ohlcCandle[0]?.low.toFixed(2)}</a>
                            <br />
                            {(
                              100 -
                              (ohlcCandle[0]?.low / lastPrice) * 100
                            ).toFixed(2)}
                            %
                            <br />
                          </>
                        ) : (
                          <>
                            <a
                              style={{
                                color: lastPriceColor,
                              }}
                            >
                              {ohlcCandle[0]?.low.toFixed(2)}
                            </a>
                            <br />
                            {(
                              100 -
                              (ohlcCandle[0]?.low / lastPrice) * 100
                            ).toFixed(2)}
                            %
                            <br />
                          </>
                        )}
                        {/* <a> {ohlcCandle[0].low.toFixed(2)}</a>
                        <br />
                        {(100 - (ohlcCandle[0].low / lastPrice) * 100).toFixed(
                          2
                        )}
                        % &nbsp;
                        <br /> */}
                        {/* Low:{" "}
                        {((lastPrice / ohlc.low) * 100).toFixed(2)}% */}
                      </td>
                      {/* <>
                        <td style={styles.cell}>{latestCandle?.high ?? "-"}</td>
                        <td style={styles.cell}>{latestCandle?.low ?? "-"}</td>
                      </>

                      <td style={styles.cell}>{ohlc.high}</td>
                      <td style={styles.cell}>{ohlc.low}</td>
                      <td style={styles.cell}>{(ohlc.high + ohlc.low) / 2}</td>
                      <td style={styles.cell}>
                        {(latestCandle?.high + latestCandle?.low) / 2}
                      </td> */}
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      </div>
      {loading && <div style={styles.loader}>Loading...</div>}
      <div style={styles.countdown}>
        Next refresh in: {Math.floor(countdown / 60)}:
        {String(countdown % 60).padStart(2, "0")}
      </div>
      <div style={styles.legendContainer}>
        <a style={styles.legendItem}>
          <span
            style={{ ...styles.legendBox, backgroundColor: "green" }}
          ></span>
          Last Price is **greater than or equal to** the High Price of the
          current candle
        </a>
        <a style={styles.legendItem}>
          <span style={{ ...styles.legendBox, backgroundColor: "red" }}></span>
          Last Price is **less than or equal to** the Low Price of the current
          candle
        </a>
        <a style={styles.legendItem}>
          <span
            style={{ ...styles.legendBox, backgroundColor: "yellow" }}
          ></span>
          Last Price is **between** the High and Low Prices of the current
          candle
        </a>
        <a style={styles.legendItem}>
          <span
            style={{ ...styles.legendBox, backgroundColor: "black" }}
          ></span>
          Last Price is **exactly equal to** the High or Low Price of the
          current candle
        </a>
      </div>
    </div>
  );
};

// Define your styles object as before
// Styling for the component
const styles = {
  container: {
    padding: "0px",
    width: "100%",
    boxSizing: "border-box",
  },
  loader: {
    margin: "10px 0",
    fontSize: "16px",
    color: "#007bff",
  },
  countdown: {
    fontSize: "16px",
    margin: "10px 0",
    color: "#007bff",
    textAlign: "center",
  },
  legendContainer: {
    margin: "20px 0",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
  },
  legendItem: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1px",
  },
  legendBox: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
    display: "inline-block",
  },
  tableContainer: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
    tableLayout: "auto", // Allow the table to manage its column width
    borderCollapse: "collapse",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    overflow: "hidden",
    margin: "2px",
    textAlign: "center",
  },
  headerCell: {
    backgroundColor: "#007bff",
    color: "#fff",
    fontWeight: "bold",
    fontSize: "12px",
    border: "1px solid grey",

    textAlign: "center",
  },
  row: {
    transition: "background-color 0.3s",
  },
  cell: {
    border: "1px solid grey",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",

    whiteSpace: "nowrap", // Prevents text from wrapping
    padding: "8px 10px", // Smaller padding for smaller screens
  },
};

// Example of using media queries in JavaScript to adjust styles based on screen width
const adjustTableForSmallScreens = () => {
  if (window.innerWidth < 768) {
    styles.table.minWidth = "100%"; // Set the minWidth to 100% for smaller screens
    styles.cell.padding = "4px 6px"; // Reduce padding for cells on smaller screens
    styles.cell.fontSize = "12px"; // Reduce font size for better fit
  }
};

// Invoke this function when the component mounts or on window resize event
window.addEventListener("resize", adjustTableForSmallScreens);
adjustTableForSmallScreens(); // Call initially to set up

export default TradesTable;
