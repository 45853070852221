import create from "zustand";
import axios from "axios";
import { baseUrl } from "../api/api.js";

const useCandleStore = create((set) => ({
  candles: [],
  isLoading: false,
  error: null,

  fetchCandles: async () => {
    set({ isLoading: true, error: null }); // Set loading state
    try {
      const response = await axios.get(
        `${baseUrl}/sensexroutes/get/fetchAndSaveCandles`
      );
      set({ candles: response.data.candles, isLoading: false }); // Update candles and loading state
    } catch (error) {
      set({ error: error.message, isLoading: false }); // Handle errors
    }
  },
}));

export default useCandleStore;
