import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import ReactFlows from "reactflow";

import ComingSoon from "./ComingSoon.js";
import AskTradesIndex from "./components/askTrades/askTradesIndex.js";
import TradesTable2 from "./components/askTrades/tradesTable2.js";

const App = () => {
  const navigate = useNavigate();

  return (
    <Routes>
      <Route index element={<ComingSoon />} />
      <Route path="/askTrades" element={<AskTradesIndex />} />
      <Route path="/askTrades2" element={<TradesTable2 />} />
    </Routes>
  );
};

export default App;
