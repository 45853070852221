import React from "react";
import TradesTable from "./tradesTable.js";
import SubmitTokenPage from "./SubmitTokenPage.js";

const AskTradesIndex = () => {
  const url =
    "https://kite.zerodha.com/connect/login?v=3&api_key=vcb0q8xz1f55z5eq";

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        alert("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <div style={styles.container}>
      <TradesTable />

      <div style={styles.header}>
        <h1 style={styles.title}>Login to Kite and Pass the Token</h1>
        <button onClick={copyToClipboard} style={styles.button}>
          Copy Login URL
        </button>
      </div>
      <SubmitTokenPage />

      <div style={styles.tableContainer}></div>
    </div>
  );
};

// Styling for the component
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    backgroundColor: "#f5f5f5", // Light background for contrast
    minHeight: "100vh",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "24px",
    color: "#333", // Dark text color for readability
  },
  link: {
    display: "inline-block",
    marginTop: "10px",
    padding: "10px 20px",
    backgroundColor: "#007bff", // Primary button color
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s",
  },
  linkHover: {
    backgroundColor: "#0056b3", // Darker shade on hover
  },
  tableContainer: {
    width: "100%",
    maxWidth: "100%", // Maximum width for the table
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)", // Shadow for elevation
    borderRadius: "8px",
    overflow: "hidden",
    backgroundColor: "#fff", // White background for the table
    margin: "10px",
  },
};

export default AskTradesIndex;
