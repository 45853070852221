import create from "zustand";
import { baseUrl } from "../api/api.js";

// Store for handling token requests
const useFeatureAStore = create((set) => ({
  tokenData: null, // State to hold the token response

  // Action to make a POST API call and save the response
  requestToken: async (requestToken) => {
    try {
      const response = await fetch(`${baseUrl}/sensexroutes/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ requestToken }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      set({ tokenData: data }); // Save the response in the store
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  },
}));

export default useFeatureAStore;
