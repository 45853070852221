import React, { useState, useEffect } from "react";
import useFeatureAStore from "../../stores/featureAStore.js";

const SubmitTokenPage = () => {
  const [requestToken, setRequestToken] = useState("");
  const [showResponse, setShowResponse] = useState(false);
  const requestTokenAction = useFeatureAStore((state) => state.requestToken);
  const tokenData = useFeatureAStore((state) => state.tokenData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (requestToken) {
      requestTokenAction(requestToken); // Trigger API call
      setShowResponse(true); // Show response
      setRequestToken(""); // Clear form
    }
  };

  // Reset token response after 1 minute
  useEffect(() => {
    if (showResponse) {
      const timer = setTimeout(() => {
        useFeatureAStore.setState({ tokenData: null }); // Reset tokenData in Zustand
        setShowResponse(false); // Hide response
      }, 60000); // 60 seconds

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [showResponse]);

  return (
    <div style={styles.container}>
      <h1>Submit Request Token</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <input
          type="text"
          placeholder="Enter Request Token"
          value={requestToken}
          onChange={(e) => setRequestToken(e.target.value)}
          style={styles.input}
          required
        />
        <button type="submit" style={styles.button}>
          Submit
        </button>
      </form>
      {showResponse && tokenData && (
        <div style={styles.responseContainer}>
          <h2>Token Response:</h2>
          <pre>{JSON.stringify(tokenData, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

// Basic styles for the component
const styles = {
  container: {
    padding: "20px",
    maxWidth: "600px",
    margin: "0 auto",
    border: "1px solid #ccc",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  input: {
    padding: "10px",
    marginBottom: "10px",
    borderRadius: "4px",
    border: "1px solid #ccc",
  },
  button: {
    padding: "10px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007bff",
    color: "#fff",
    cursor: "pointer",
  },
  responseContainer: {
    marginTop: "20px",
    padding: "10px",
    border: "1px solid #eaeaea",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
};

export default SubmitTokenPage;
